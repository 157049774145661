import { useTranslation } from "react-i18next";
import {Wrapper} from './styled-index'
import Hamburger from "./../../../assets/Headers/header-hamburger.svg"
import FlagUzb from "./../../../assets/uzb_flag.png"
import FlagEng from "./../../../assets/eng_flag.png"
import FlagRus from "./../../../assets/rus_flag.png"
function LanguageHeader({HandleClick}) {
  const { t, i18n } = useTranslation();
    const handleLang = (e) =>{
        const lang = e.target.value
        i18n.changeLanguage(lang)
        window.location.reload() 
      }
      function LanguValue() {
        return window.localStorage.getItem("i18nextLng")
      }

    return(
        <Wrapper>
          {LanguValue() === "uz" ?(
          <img src={FlagUzb} width={24} height={24} alt="" />) : LanguValue() === "en" ?(
            <img src={FlagEng} width={24} height={24} alt="" />) : LanguValue() === "ru" ?(
              <img src={FlagRus} width={24} height={24} alt="" />):null}
          <select onChange={handleLang}>
            {LanguValue() === "ru" ?(
              <>
              <option value="ru">RUS</option>
              <option value="uz">UZB</option>
              <option value="en">ENG</option>
              </>
            ) 
            : LanguValue() === 'uz'?(
              <>                         
              <option value="uz">UZB</option>
               <option value="ru">RUS</option>
              <option value="en">ENG</option>
              </>
            ) 
            : LanguValue() === 'en'?(
              <>                         
              <option value="en">ENG</option>
              <option value="uz">UZB</option>
               <option value="ru">RUS</option>
              </>
            ):(
              <>                         
               <option value="ru">RUS</option>
              <option value="en">ENG</option>
              <option value="uz">UZB</option>
              </>
            )}
          </select>
          <button onClick={HandleClick}><img src={Hamburger} width={36} height={24} alt="menu" /></button>
        </Wrapper>
    )
}
export default LanguageHeader