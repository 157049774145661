import { configureStore } from "@reduxjs/toolkit";
import ContactSlice from './contact/index';
import CategorySlice from './category/index'
import ProductSlice from './products/index'
import UslugySlice from "./uslugy/index"
import BlogSlice from "./blog/index"
export const store = configureStore({
  reducer: {
    contact: ContactSlice,
    category: CategorySlice,
    product: ProductSlice,
    uslugy : UslugySlice,
    blog : BlogSlice
  },
});