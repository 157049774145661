import React from "react";
import { NavLink } from "react-router-dom";
import NotFoundImg from "../../assets/notfound/notfoundImg.png";
import styles from "./style.module.css";

function NotFound() {
  return (
    <>
      <div className={styles.Wrapper}>
        <h2>404</h2>
        <img src={NotFoundImg} alt="photo" />
        <NavLink to={"/"}>Back to Home</NavLink>
      </div>
    </>
  );
}

export default NotFound;
