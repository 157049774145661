import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const UslugyGet = createAsyncThunk("Uslugy/get", async () => {
  return await axios
    .get(`${API_URL}/uslugy`)
    .then((response) => response.data);
});

const UslugySlice = createSlice({
  name: "Uslugy",
  initialState: {
    UslugyGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },
  },
  extraReducers: {
    // get
    [UslugyGet.pending]: (state, action) => {
      state.UslugyGet.loading = true;
    },
    [UslugyGet.fulfilled]: (state, action) => {
      state.UslugyGet.loading = false;
      state.UslugyGet.success = true;
      state.UslugyGet.data = action.payload;
      state.UslugyGet.error = false;
    },
    [UslugyGet.rejected]: (state, action) => {
      state.UslugyGet.loading = false;
      state.UslugyGet.error = true;
      state.UslugyGet.success = false;
    },
  },
});

export const {} = UslugySlice.actions;
export default UslugySlice.reducer;
